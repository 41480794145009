import React from "react"
import Grid from "../components/gridLayout"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

export default () => {
  const query = useStaticQuery(graphql`
    query {
      cfw101: file(relativePath: { eq: "education/cfw101.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      first30days: file(relativePath: { eq: "education/30days.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ammp: file(relativePath: { eq: "education/ammp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smam: file(relativePath: { eq: "education/smam.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consulting: file(relativePath: { eq: "education/consulting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bbb: file(relativePath: { eq: "education/bbb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const education = [
    {
      title: "Coding For the Web 101",
      thumb: query.cfw101.childImageSharp.fluid,
      link: "/education/coding-for-the-web-101",
    },
    {
      title: "First 30 Days: The Millionaire Mindset",
      thumb: query.first30days.childImageSharp.fluid,
      link: "/education/first-30-days-the-millionaire-mindset",
    },
    {
      title: "Amazon Millionaire Mentorship Program",
      thumb: query.ammp.childImageSharp.fluid,
      link: "/education/amazon-millionaire-mentorship-program",
    },
    {
      title: "Social Media Affiliate Marketing",
      thumb: query.smam.childImageSharp.fluid,
      link: "/education/social-media-affiliate-marketing",
    },
    {
      title: "30 Minute Consulting Call",
      thumb: query.consulting.childImageSharp.fluid,
      link: "/education/consulting-call-with-jordan",
    },
    // {
    //   title: "Business Builder Basics",
    //   thumb: query.bbb.childImageSharp.fluid,
    //   link: "/education",
    // },
  ]
  return (
    <Layout>
      <SEO
        title="Academy"
        description="The main hub for all of the programs and courses you need to be a world class entrepreneur. "
      />
      <h1 style={{textAlign:'center'}}>Millionaire Millennial Academy Courses</h1>
      <Grid items={education} />
    </Layout>
  )
}
